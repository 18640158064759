<template>
  <div class="checTestPage">
    <div class="topic_warp_backg1">
      <LEFTIMAGE ref="imageRef"
                  v-if="Object.keys(imageList).length"
                 :imageList="imageList" />
      <div v-else
           class="zanwu">
        <img src="@/assets/none.jpg"
             alt="">
      </div>
    </div>
    <div class="topic_warp_backg1">
      <RIGHTTOPIC ref="topiccRef"
                  :topic="topicList[index]"
                  :topicIndex="topicList[index].paper_question_no" />
    </div>
  </div>
</template>
  
<script>
import LEFTIMAGE from './components/image/index.vue'
import RIGHTTOPIC from './components/topic/index.vue'
export default {
  data () {
    return {
      imageList: [],
      id: '',
      judge_status: '',
      index: '',
      topicList: [{ paper_question_no: '' }]
    }
  },
  components: {
    LEFTIMAGE, RIGHTTOPIC
  },
  watch: {
    index () {
      if (this.topicList[this.index].is_ignored) {
        this.topicList[this.index].user_score = 0
      }
    }
  },
  created () {
    this.index = 0
  },
  mounted () {
    this.id = this.$route.query.id
    this.judge_status = this.$route.query.judge_status
    this.getTopic()
  },
  methods: {
    removeChoose () {
      var isChooseList = '1,12,14,15,25,26,27,34,38,46,47,48,54,56,65,67,69,70,71,78,81'
      isChooseList = isChooseList.split(',')

      let copyTopic = []
      // for (let index = 0; index < copyTopic.length; index++) {
      //   if (isChooseList.indexOf(copyTopic[index].question_type_id) != -1) {
      //     copyTopic.splice(index, 1)
      //     index--
      //   }
      // }
      this.topicList.forEach(item => {
        if (isChooseList.indexOf(item.question_type_id) == -1) {
          copyTopic.push(item)
        }
      });
      this.topicList = copyTopic
    },
    getTopic () {
      var url = ''
      if (this.$route.query.isErr) {
        url = '/api/v1/error/question_approval'
      } else {
        url = '/api/v1/papers/correct'
      }
      this.$http({
        url: url,
        method: 'get',
        params: {
          user_paper_id: this.id,
          judge_status: this.judge_status,
          question_error_id: this.id
        }
      }).then(res => {
        if (this.$route.query.isErr) {
          this.topicList = [res.data]
          this.imageList = JSON.parse(res.data.other_answer)
          this.addHeader()

        } else {
          this.topicList = res.data.list
          this.removeChoose()
          this.imageList = res.data.other_answer ? JSON.parse(res.data.other_answer) : []
          this.addHeader()
        }

      }).catch(e => {
        // this.$message.warning(e.msg)
        // this.$router.go(-1)
      })
    },
    addHeader () {
      for (let index = 0; index < this.imageList.length; index++) {
        this.imageList[index] = this.$addHeader(this.imageList[index])
      }
    },
    // 点击下一题
    add () {
      if (this.index == this.topicList.length - 1) {
        return this.$message.warning('已经是最后一题')
      }
      this.index += 1
    },
    // 点击上一题
    sub () {
      if (this.index == 0) {
        return this.$message.warning('已经是第一题')
      }
      this.index -= 1
    },
    submitTopic () {
      this.topicList[this.index].is_ignored = 1
      if (this.topicList.length == 0) {
        this.$notify({
          title: '提示',
          message: '试卷修改完成，已经为您跳转主页!',
          type: 'success'
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checTestPage {
  display: flex;
  height: 100%;
}
.slide_block_warp {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .block {
    background: #3f9eff;
    width: 25px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
.topic_warp_backg {
  flex: 1;
  margin-top: 0;
}
.topic_warp_backg1 {
  width: 49%;
  margin-top: 0;
}
.zanwu {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}
</style>